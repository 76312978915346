<template>
<b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                Add New User
            </h5>

            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

        </div>
        <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
            <div class="alert-body">
                <span>{{errorMessage}}</span>
            </div>
        </b-alert>
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

                <!-- Full Name -->
                <validation-provider #default="validationContext" name="First Name" rules="required">
                    <b-form-group label="First Name" label-for="first-name">
                        <b-form-input id="first-name" v-model="fname" autofocus :state="getValidationState(validationContext)" trim placeholder="John" />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Last Name -->
                <validation-provider #default="validationContext" name="Last Name" rules="required">
                    <b-form-group label="Last Name" label-for="last-name">
                        <b-form-input id="last-name" v-model="lname" :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Middle Name -->
                <validation-provider #default="validationContext" name="Middle Name" rules="required">
                    <b-form-group label="Middle Name" label-for="middle-name">
                        <b-form-input id="middle-name" v-model="mname" :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- Email -->
                <validation-provider #default="validationContext" name="Email" rules="required|email">
                    <b-form-group label="Email" label-for="email">
                        <b-form-input id="email" v-model="email" :state="getValidationState(validationContext)" trim />

                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <!-- phone -->
                <b-form-group label="Phone Number" label-for="phone">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-form-select v-model="countryCode" :options="countryCodeOptions" />
                        </b-input-group-prepend>
                        <cleave id="phone" v-model="phone" class="form-control" :raw="false" :options="options.phone" placeholder="725560980" />
                    </b-input-group>

                </b-form-group>
                <!-- Role -->
                <validation-provider name="Role" rules="required">
                    <b-form-group label="Role" label-for="role">
                        <v-select v-model="roleID" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="roleOptions" :reduce="val => val.value" :clearable="false" input-id="user-role" />
                    </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit" id="addUser" @click="accountCreationForm">
                        <b-spinner small v-show="isLoading" />
                        Add
                    </b-button>
                    <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
                        Cancel
                    </b-button>
                </div>

            </b-form>
        </validation-observer>
    </template>
</b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    BAlert,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
} from 'bootstrap-vue'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    ref
} from '@vue/composition-api'
import {
    required,
    alphaNum,
    email
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import $ from 'jquery'
import Cleave from 'vue-cleave-component'
import {
    heightFade
} from '@core/directives/animations'
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        vSelect,
        BInputGroupPrepend,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BAlert,
        BFormSelect,
        Cleave,
        BInputGroup,
        BSpinner,
        BAlert,
    },
    model: {
        prop: 'isAddNewUserSidebarActive',
        event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
        isAddNewUserSidebarActive: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email: '',
            fname: '',
            lname: '',
            phone: '',
            mname: '',
            roleID: '',
            isLoading: false,
            countryCode: '254',
            options: {
                phone: {
                    phone: true,
                    phoneRegionCode: 'KE',
                },
            },
            errorMessage: "",
            countryCodeOptions: [],
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            roleOptions: []
        }
    },
    setup(props, {
        emit
    }) {

        const blankUserData = {
            fullName: '',
            username: '',
            email: '',
            role: null,
            currentPlan: null,
            company: '',
            country: '',
            contact: '',
        }

        const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
        const resetuserData = () => {
            userData.value = JSON.parse(JSON.stringify(blankUserData))
        }

        const onSubmit = () => {

            store.dispatch('app-user/addUser', userData.value)
                .then(() => {
                    emit('refetch-data')
                    emit('update:is-add-new-user-sidebar-active', false)
                })
        }

        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation(resetuserData)

        return {
            userData,
            onSubmit,

            refFormObserver,
            getValidationState,
            resetForm,
        }
    },
    mounted() {
        let vm = this
        vm.getCountries()
        vm.getRoles()
    },
    directives: {
        heightFade,
        Ripple,
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        getCountries() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + 'account/v1/view/countries',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + store.state.accessToken
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {} else {
                        let result = response.data.data;

                        for (const [key, value] of Object.entries(result)) {
                            console.log(`${key}: ${value[0]}`);
                            var country = {
                                value: `${key}`,
                                text: `${value[0]}` + " (+" + `${key}` + ")"

                            }
                            vm.countryCodeOptions.push(country)
                        }

                    }

                },
                error: function (jQxhr, status, error) {}
            });
        },
        accountCreationForm() {
            let vm = this
            vm.isLoading = true
            $('#addUser').html(' Please Wait ...');
            $.post({
                url: store.state.rootUrl + 'account/v1/add_users',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    mobile: vm.phone,
                    countryCode: vm.countryCode,
                    emailAddress: vm.email,
                    firstName: vm.fname,
                    middleName: vm.mname,
                    lastName: vm.lname,
                    roleId: vm.roleID,
                    clientId: vm.$cookies.get("clientData").clientId
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    $('#addUser').html(' Add');
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    $('#addUser').html(' Add');
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });

        },
        getRoles() {
            let vm = this
            //clientData
            $.get({
                url: store.state.rootUrl + '/account/v1/view/user_roles',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {} else {
                        let result = response.data.data.data;
                        for (let i = 1; i < result.length; i++) {
                            var roles = {
                                label: result[i].role_name,
                                value: result[i].role_id,
                            }
                            vm.roleOptions.push(roles)

                        }

                        console.log(JSON.stringify(vm.roleOptions))

                    }

                },
                error: function (jQxhr, status, error) {}
            });
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
