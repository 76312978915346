<template>
<div>

    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions" @refetch-data="refetchData" />

    <!-- Filters -->
    <users-list-filters :role-filter.sync="roleFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :status-options="statusOptions" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>Show</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>entries</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                            <span class="text-nowrap">Add User</span>
                        </b-button>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refUserListTable" class="position-relative" :items="users" responsive :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">

            <!-- Column: User -->
            <template #cell(fullNames)="data">
                <b-media vertical-align="center">
                    <template #aside>
                        <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.first_name)" :variant="`light-${resolveUserRoleVariant(data.item.role_name)}`" :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }" />
                    </template>
                    <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }" class="font-weight-bold d-block text-nowrap">
                        {{ data.item.first_name }}  {{ data.item.middle_name }}
                    </b-link>
                    <small class="text-muted">@{{ data.item.full_names }}</small>
                </b-media>
            </template>

            <!-- Column: Role -->
            <template #cell(role_name)="data">
                <div class="text-nowrap">
                    <feather-icon :icon="resolveUserRoleIcon(data.item.role_name)" size="18" class="mr-50" :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
                    <span class="align-text-top text-capitalize">{{ data.item.role_name }}</span>
                </div>
            </template>

            <!-- Column: Status -->
            <template #cell(account_status)="data">
                <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.account_status)}`" class="text-capitalize">
                    {{ data.item.account_status | statusVerified }}
                </b-badge>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.user_id } }">
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Details</span>
                    </b-dropdown-item>

                    <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.user_id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                    </b-dropdown-item>

                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserListAddNew from './UserListAddNew.vue'
import $ from 'jquery'

export default {
    components: {
        UsersListFilters,
        UserListAddNew,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
    },
    data() {
        return {
            roleFilter:"",
            statusFilter:"",
            users: [],
            totalRecords: 0,
            columns: [{
                    key: 'fullNames',
                    sortable: true
                },
                {
                    key: 'middle_name',
                    sortable: true
                },
                {
                    key: 'email_address',
                    sortable: true
                },
                {
                    key: 'client_name',
                    sortable: true
                },
                {
                    key: 'msisdn',
                    sortable: true
                },
                {
                    key: 'role_name',
                    sortable: true
                },
                {
                    key: 'account_status',
                    sortable: true
                },
                {
                    key: 'actions'
                },
            ],
            roleOptions:[],
            statusOptions: [{
                label: 'Active',
                value: 1
            },
            {
                label: 'Unverified',
                value: 0
            },
            {
                label: 'Disabled',
                value: 3
            },
        ]
        }
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })

        const isAddNewUserSidebarActive = ref(false) 

        const {
          
            tableColumns,
            perPage,
            currentPage,
           
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
        } = useUsersList()

        return {

            // Sidebar
            isAddNewUserSidebarActive,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            refetchData,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
        }
    },
    mounted() {
        this.getAllUsers()
        this.getRoles()
    },
     watch: {
        roleFilter: function (value) {
            console.log("role filter "+value)
        }
    },

    methods: {
        getRoles() {
        let vm = this
        //clientData
        $.get({
            url: store.state.rootUrl + '/account/v1/view/user_roles',
            type: "get",
            async: true,
            crossDomain: true,
            headers: {
                "X-Authorization-Key": vm.$cookies.get("token"),
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json",
            },
            success: function (response, status, jQxhr) {

                $('#resetPWD').html(' Reset Password');
                if (response.data.code != 200) {} else {
                    let result = response.data.data.data;
                    for (let i = 1; i < result.length; i++) {
                        var roles = {
                            label: result[i].role_name,
                            value: result[i].role_id,
                        }
                        vm.roleOptions.push(roles)

                    }

                    console.log(JSON.stringify(vm.roleOptions))

                }

            },
            error: function (jQxhr, status, error) {}
        });
      },
        getAllUsers() {
            let vm = this
            
            $.get({
                url: store.state.rootUrl + 'account/v1/view/users',
                type: "get",
                async: true,
                crossDomain: true,
                data: {
                    limit: 100
                },
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                success: function (response, status, jQxhr) {

                    vm.users = response.data.data.data
                    vm.totalRecords = response.data.data.total_count
                },
                error: function (jQxhr, status, error) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Error fetching users list',
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            });
        }
    },
     filters: {
        statusVerified(value){
            if(value == 1){
                return "Active"
            }
            else if(value == 2) {
                return "Unverified"
            }
            else if(value == 3) {
                return "Disabled"
            }
            else {
                return "Pending"
            }
        },
     }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
